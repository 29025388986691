<template>
  <div :class="'popup_wrap popup_wrap_pdf ' + layerStyle">
    <button type="button" class="layer_close" @click="$emit('close')">close</button>
    <template v-if="type === 'A'">
      <div class="popup_cont">
        <h1 class="page_title">B/L Copy</h1>
        <div class="content_box">
          <p class="text_center mt10 mb10">
            <input v-show="oblYn === 'Y' && memberDetail.userCtrCd === 'KR'" type="checkbox" id="bl_copy_pop_chk0" v-model="params.oblYn" true-value="Y" false-value="N">
            <label v-show="oblYn === 'Y' && memberDetail.userCtrCd === 'KR'" for="bl_copy_pop_chk0" class="mr20"><span></span>Original Copy</label>

            <input v-show="chkEsign === 'Y'" type="checkbox" id="bl_copy_pop_chk1" v-model="params.eSignYn" true-value="Y" false-value="N">
            <label v-show="chkEsign === 'Y'" for="bl_copy_pop_chk1" class="mr20"><span></span>E-Sign</label>

            <input type="checkbox" id="bl_copy_pop_chk2" v-model="params.porDlyYn" true-value="Y" false-value="N">
            <label for="bl_copy_pop_chk2" class="mr20"><span></span>POR &amp; DLY</label>

            <input type="checkbox" id="bl_copy_pop_chk3" v-model="params.noCnee" true-value="N" false-value="Y">
            <label for="bl_copy_pop_chk3" class="mr20"><span></span>CNEE address</label>

            <input type="checkbox" id="bl_copy_pop_chk4" v-model="params.noNotify" true-value="N" false-value="Y">
            <label for="bl_copy_pop_chk4" class="mr20"><span></span>Notify address</label>

            <input v-show="chkRider === 'Y'" type="checkbox" id="bl_copy_pop_chk5" v-model="params.rider" true-value="Y" false-value="N">
            <label v-show="chkRider === 'Y'" class="mr20" for="bl_copy_pop_chk5"><span></span>Rider</label>

            <input type="checkbox" id="bl_copy_pop_chk6" v-model="params.cntrChgColYn" true-value="Y" false-value="N">
            <label for="bl_copy_pop_chk6" class="mr20"><span></span>CNTR Info(Gross wt,pkgs,seal)</label>
          </p>
        </div>
        <div class="mt10 text_center">
          <a class="button blue lg" href="#" @click.prevent="fnChangePrint();">{{ $t('msg.ARAI200.007') }}</a>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="popup_cont">
        <h1 class="page_title">B/L Copy</h1>
        <div class="content_box">
          <div class="clearfix">
            <div class="float_left col_3">
              <table class="tbl_list font_sm gridtype">
                <colgroup>
                  <col width="100%">
                </colgroup>
                <thead>
                  <tr>
                    <th>{{ $t('msg.REPORT_PDF.002') }}<!-- B/L No. --></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(vo, idx) in items" :key="'tbl_' + idx">
                    <td>{{ vo.blNo }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float_left text_center col_7 pl20" style="max-height:500px;overflow-y:auto">
              <vue-pdf-app
                style="height: 300px;"
                :pdf="pdf.pdfUrl"
                :config="pdfConfig.config"
                :id-config="pdfConfig.idConfig"
                theme="light"
              ></vue-pdf-app>
              <div class="paging print">
                <span class="num">
                  <input type="number" :id="pdfConfig.idConfig.pageNumber" class="pageNumber" maxlength="2">
                  <span :id="pdfConfig.idConfig.numPages" class="pageNumber">-</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="mt10 text_center">
          <a class="button blue lg" href="#" @click.prevent="fnDownlaod();">{{ $t('msg.REPORT_PDF.101') }}<!-- 다운 --></a>
          <a class="button blue lg" :id="pdfConfig.idConfig.print">{{ $t('msg.REPORT_PDF.102') }}<!-- 인쇄 --></a>
          <a class="button blue lg" href="#" @click.prevent="openSendEmailPopup();">{{ $t('msg.REPORT_PDF.103') }}<!-- 공유 --></a>
        </div>
      </div>

      <win-layer-pop class="pdf_send_email">
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closeSendEmailPopup"
          :parent-info="sendEmailParam"
        />
      </win-layer-pop>
    </template>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import commonPdf from '@/plugins/commonPdf'
import reportInfo from '@/api/rest/trans/reportInfo'
import 'vue-pdf-app/dist/icons/main.css'

export default {
  name: 'BlCopyPop',
  components: {
    vuePdfApp: () => import('vue-pdf-app'),
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    PdfSendEmailPop: () => import('@/pages/pdf/popup/PdfSendEmailPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      customComponent: null,
      sendEmailParam: {},
      type: 'A',
      layerStyle: 'width_800',
      params: {
        eSignYn: 'N',
        porDlyYn: 'N',
        noCnee: 'N',
        noNotify: 'N',
        rider: 'Y',
        oblYn: 'N',
        cntrChgColYn: 'N'
      },
      chkRider: '',
      chkEsign: '',
      items: [],
      userInfo: {},
      pdf: {
        pdfUrl: '',
        emailUrl: '',
        emailFiles: [],
        pg: 1,
        pageCount: 0,
        currentPage: 0
      },
      pdfConfig: {
        config: {},
        idConfig: {}
      },
      oblYn: ''
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    parentInfo () {
      if (this.$props.parentInfo.items !== undefined) {
        this.type = 'A'
        this.layerStyle = 'width_600'
        this.items = this.$_.cloneDeep(this.$props.parentInfo.items)
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      this.pdfConfig = commonPdf.getPdfConfig()

      // 로그인 여부 체크
      if (!this.auth.userId) {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.REPORT_PDF.115') }) // 로그인을 확인해주세요.
        this.$emit('close')
        return
      }

      if (this.$props.parentInfo.items !== undefined) {
        this.items = this.$_.cloneDeep(this.$props.parentInfo.items)
        // this.setItems(this.$props.parentInfo.items)

        if (this.type === 'A') {
          const strParams = [] // bl 추가정보 조회 파라미터
          let widthCnt = 3

          this.items.forEach(vo => {
            strParams.push('arrBlNo=' + vo.blNo)
          })

          await Promise.all([
          reportInfo.getReportInfoBlCopy(strParams.join('&'))
            .then(res => {
              if (res.data) {
                console.log('getReportInfoBlCopy @@@@@ ', res)

                let riderYcnt = 0
                let eSignYcnt = 0
                let oblCnt = 0

                this.items.forEach(p => {
                  const tvo = res.data[p.blNo]

                  if (tvo) {
                    if (tvo.porDlyYn === 'Y') {
                      this.params.porDlyYn = 'Y'
                    }

                    if (tvo.riderYn === 'Y') {
                      riderYcnt += 1
                    }

                    if (tvo.blcopyKrSignYn === 'Y') {
                      eSignYcnt += 1
                    }

                    if (tvo.blCopyOblYn === 'Y') {
                      oblCnt += 1
                    }
                  }
                })

                // BL들 중에 rider 포함된 BL 있으면, RIDER 버튼 보이기
                if (riderYcnt > 0) {
                  this.chkRider = 'Y'
                  widthCnt += 1
                } else {
                  this.chkRider = 'N'
                }

                // BL들 중에 blcopyKrSignYn 포함된 BL 있으면, E-Sign 버튼 보이기
                if (eSignYcnt > 0) {
                  this.chkEsign = 'Y'
                  widthCnt += 1
                } else {
                  this.chkEsign = 'N'
                }

                // BL들 중에 OBL 포함이면 버튼 보이기
                if (oblCnt > 0) {
                  this.oblYn = 'Y'
                  widthCnt += 1
                } else {
                  this.oblYn = 'N'
                }
              }

              if (widthCnt === 4 || widthCnt === 5) {
                this.layerStyle = ' width_1000'
              } else if (widthCnt > 5) {
                this.layerStyle = ' width_1500'
              }
            })
          ])
        }
      }

      commonPdf.commonInit()
    },
    fnChangePrint () {
      if (this.type === 'A') {
        this.type = 'B'
        this.layerStyle = ' width_1000'

        this.items.forEach(vo => {
          vo.porDlyYn = this.params.porDlyYn
          vo.noCnee = this.params.noCnee
          vo.noNotify = this.params.noNotify
          vo.rider = this.params.rider
          vo.eSignYn = this.params.eSignYn
          vo.oblYn = this.params.oblYn
          vo.cntrChgColYn = this.params.cntrChgColYn
        })

        this.setItems(this.items)
      }
    },
    async setItems (items) {
      const strParams = [] // bl 추가정보 조회 파라미터
      const usrParams = [] // user 정보 조회 파라미터

      items.forEach(vo => {
        strParams.push('arrBlNo=' + vo.blNo)
      })

      let userId = this.auth.userId
      let ctrCd = this.memberDetail.userCtrCd
      let bkgPlcCd = this.memberDetail.bkgPlcCd

      if (commonPdf.isNotPRD()) {
        if (this.$props.parentInfo.testUserId) {
          userId = this.$props.parentInfo.testUserId
        }
        if (this.$props.parentInfo.testCtrCd) {
          ctrCd = this.$props.parentInfo.testCtrCd

          strParams.push('testCtrCd=' + ctrCd)
          usrParams.push('testCtrCd=' + ctrCd)
        }
        if (this.$props.parentInfo.testBkgPlcCd) {
          bkgPlcCd = this.$props.parentInfo.testBkgPlcCd

          strParams.push('testBkgPlcCd=' + bkgPlcCd)
          usrParams.push('testBkgPlcCd=' + bkgPlcCd)
        }
      }

      await Promise.all([
        reportInfo.getReportInfoUser(usrParams.join('&'))
          .then(res => {
            this.userInfo = res.data
          }),
        reportInfo.getReportInfoBlCopy(strParams.join('&'))
          .then(res => {
            if (res.data) {
              items.forEach(p => {
                const tvo = res.data[p.blNo]
                if (tvo) {
                  p.clauseYn = tvo.clauseYn || ''
                  p.elPrintYn = tvo.elPrintYn || ''
                  p.exRateYn = tvo.exRateYn || ''
                  p.signYn = tvo.signYn
                  p.elPrintYn = tvo.elYn
                  p.blCopyOblYn = tvo.blCopyOblYn
                  p.cntrChgColYn = this.params.cntrChgColYn

                  // 라이더 값 세팅
                  if (this.chkRider === 'Y') {
                    p.riderYn = p.rider
                  } else {
                    p.riderYn = this.chkRider
                  }
                }
              })
              this.items = items
            }
          })
      ])

      const usrVo = {
        ctrCd: ctrCd,
        bkgPlcCd: bkgPlcCd,
        userId: userId,
        agtCd: this.userInfo.agtCd,
        ctrCurCd: this.userInfo.ctrCurCd,
        oblYn: this.params.oblYn
      }

      this.pdf = await commonPdf.setBlCopy(this.items, usrVo)
    },
    fnDownlaod () {
      const items = this.items || []

      if (items.length === 0) {
        return
      }

      items.forEach(p => {
        commonPdf.download(p.pdfUrl, `KMTC${p.blNo} BL copy.pdf`)
      })
    },
    openSendEmailPopup () {
      this.sendEmailParam = {
        emailUrl: this.pdf.emailUrl,
        emailFiles: this.pdf.emailFiles,
        type: 'AN'
      }
      this.customComponent = 'PdfSendEmailPop'
      commonPdf.layerOpen('.pdf_send_email')
    },
    closeSendEmailPopup () {
      this.customComponent = null
      commonPdf.layerClose('.pdf_send_email')
    }
  }
}
</script>

<style scoped>
.width_1500 {
  width: 1500px !important;
}

.width_1000 {
  width: 1000px !important;
}
.width_600 {
  width: 600px !important;
}

.width_700 {
  width: 700px !important;
}

.width_800 {
  width: 800px !important;
}
</style>
